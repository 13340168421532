* {
  margin: 0;
  padding: 0;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  background-color: black;
}

.main {
  width: 100%;
  height: 101vh;
}

.googleform {
  width: 100%;
  height: 800px;
  border: none;
}
